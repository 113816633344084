import React from 'react';
import Layout from '../layouts/index';
import Hero from '../components/hero/Hero';
import HeroIllustration from '../components/hero/HeroIllustration';

const IndexPage = () => {
  return (
    <Layout>
      <Hero
        title="Use AI to acquire more customers. faster."
        content="JOLT empowers companies boost customer acquisition and drive more conversions faster than ever before through AI powered engagement."
        illustration={HeroIllustration}
      />
    </Layout>
  )
}

export default IndexPage;
