import React, { Fragment } from 'react';
import SideChart from './SideChart';

const HeroIllustration = () => {
  return (
    <Fragment>
      
      <div className="hero-illustration-browser is-revealing">
        <SideChart/>
      </div>
      
    </Fragment>
  )
}

export default HeroIllustration;
