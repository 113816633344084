import React, {useEffect} from 'react';
import emailjs from "emailjs-com";
import Layout from '../layouts/index';

const removeSiteHeader = () => {
  var element = document.querySelector('.site-header-large-bg');
  element.parentNode.removeChild(element);
}

const ContactPage = () => {
  useEffect(() => {
    removeSiteHeader()
  });

  
  function sendEmail(e) {
    e.preventDefault();
    emailjs.sendForm('service_ircxi4u', 'template_vmiauxe', e.target, 'user_wV2f316YChCDblI29zrPH')
        .then((result) => {
            // console.log(result.text);
        }, (error) => {
            // console.log(error.text);
        });
        e.target.reset()
}


  

  return (
    
    <Layout>
      <div className="container-sm">
                <header className="entry-header">
                  <h1 style={{"text-align": "center", "margin-top": 0}}>Contact Us</h1>
                </header>

                <div>
            <div className="container">
            <form onSubmit={sendEmail}>
                    <div className="row pt-5 mx-auto">
                        <div className="control control-expanded">
                          <label>Name <span>*</span></label>
                          <span>
                            <input type="text" className="input"  name="name"/>
                          </span>
                        </div>
                        <div className="control control-expanded">
                        <label>Your business email <span>*</span></label>
                          <span >
                            <input type="email" className="input" name="email"  size="40" aria-required="true" aria-invalid="false"></input>
                          </span>
                        </div>
                        <div className="col-8 form-group mx-auto">
                          <label>Phone Number <span>(optional)</span></label>
                          <span ><input type="tel" className="input" name="telnumber" size="40" aria-invalid="false"></input></span>
                        </div>
                        <div className="col-8 form-group mx-auto">
                        <label>Company <span></span></label><span ><input type="text" className="input" name="companyname"  size="40"  aria-invalid="false"></input></span>
                        </div>
                        <div className="col-8 form-group mx-auto">
                        <label>Country <span>*</span></label><span ><input type="text" className="input" name="countryname"  size="40" aria-required="true" aria-invalid="false"></input></span>
                        </div>
                        <div className="col-8 form-group mx-auto">
                        <label>Message (optional)</label><span ><textarea name="message" className="input" cols="40" rows="10"  aria-invalid="false"></textarea></span>
                        </div>
                        <div className="col-8 pt-3 mx-auto">
                            <input type="submit" className="button button-primary button-block button-shadow" value="Send Message"></input>
                        </div>
                    </div>
                </form>

            </div>
        </div>
              </div>
    </Layout>

  );
};

export default ContactPage;
