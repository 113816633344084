import React from 'react';
import logo from '../../assets/images/logo_jolt2.jpg'
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'white'
  },
  logo:{
    paddingTop: 8,
    width:120
  },
  flexGrow: {
    flexGrow: 1
  },
}));

const Logo = () => {
  const classes = useStyles();
  return (
    <div className={classes.logo}>
      <img src={logo} alt="" />
    </div>
  )
}

export default Logo;
